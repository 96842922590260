<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <Modal ref="modal" :width="740" disable-link data-cy="solution_manager-modal">
    <SearchField
      ref="question"
      v-model="question"
      class="sm__search_field"
      :submit="() => request(true)"
      :title-id="titleId"
      :heading-level="1"
      autofocus
    />

    <section
      v-if="showContactOptions"
      class="paragraph result-container contact-container"
    >
      <h2 :ref="contactOptionsRefName" class="subtitle" tabindex="-1">
        Wir helfen Ihnen gerne persönlich weiter!
      </h2>
      <p class="slim">
        Haben Sie noch weitere Fragen? Gerne stehen wir Ihnen persönlich über
        den Support-Chat oder die Hotline zur Verfügung.
      </p>
      <div class="contact__elements">
        <div>
          <h3 class="subtitle hotline">Hotline</h3>
          <p>
            Unsere Kundenbetreuung ist Mo.-Fr. von 08-18 Uhr und Sa. von 10-18
            Uhr für Sie da.
          </p>
          <HotlineNumber />
        </div>
        <Chat :heading-level="3" />
      </div>
    </section>

    <section
      v-else
      class="result-container"
      data-cy="solution_manager-result"
      aria-live="polite"
    >
      <h2
        v-if="hasAnswer"
        :ref="answersRefName"
        tabindex="-1"
        class="answer__headline"
      >
        Unsere Antwort:
      </h2>

      <component
        :is="includesHtmlPragraphs(answerPrefix) ? 'div' : 'p'"
        v-if="answerPrefix"
        class="answer__content slim"
        v-html="answerPrefix"
      ></component>

      <ul v-if="answerOptions.length > 0" class="solution-manager_list">
        <li
          v-for="(option, index) in answerOptions"
          :key="`answer-option-${index}`"
        >
          <ButtonComponent
            :data-cy="`answer-option-${index}`"
            button-type="look-like-link"
            :clickhandler="() => clickAnswerElement(index)"
            >{{ option }}</ButtonComponent
          >
        </li>
      </ul>

      <component
        :is="includesHtmlPragraphs(answerSuffix) ? 'div' : 'p'"
        v-if="answerSuffix"
        class="answer__content slim"
      >
        {{ answerSuffix }}</component
      >

      <div v-if="faqSuggestions.length > 0">
        <h2 class="faq__headline">
          {{
            $breakpoint.fromTablet()
              ? 'FAQs die zu dieser Frage passen könnten:'
              : 'FAQs zu Ihrer Frage:'
          }}
        </h2>

        <ul v-if="faqSuggestions.length > 0" class="solution-manager_list">
          <li
            v-for="(faq, index) in faqSuggestions"
            :key="`faq-suggestion-${index}`"
            class="faq__option"
            :data-cy="`faq-suggestion-${index}`"
          >
            <ButtonComponent
              button-type="look-like-link"
              :clickhandler="() => selectFaqSuggestion(faq)"
              >{{ faq }}
            </ButtonComponent>
          </li>
        </ul>
      </div>

      <div v-if="hasAnswer" class="alert feedback">
        <p
          v-show="sendPositiveFeedback"
          class="slim"
          :aria-live="sendPositiveFeedback ? 'assertive' : undefined"
        >
          Vielen Dank!
        </p>
        <div v-if="!sendPositiveFeedback" class="question">
          <p class="question-text">
            <strong :id="feedbackId">Hat Ihnen diese Antwort geholfen?</strong>
          </p>
          <div
            class="feedback-buttons"
            role="group"
            :aria-labelledby="feedbackId"
          >
            <ButtonComponent
              button-type="secondary"
              :clickhandler="() => submitFeedback(true)"
              data-cy="solution-manager-feedback"
              >Ja</ButtonComponent
            >
            <ButtonComponent
              button-type="secondary"
              :clickhandler="() => submitFeedback(false)"
              >Nein</ButtonComponent
            >
          </div>
        </div>
      </div>
    </section>
  </Modal>
</template>

<script>
import { getQueryUrl } from 'src/lib/url'
import { parseOptionTags, removeWhitespace } from 'src/lib/novomind_helpers'
import { uniqueId } from 'src/lib/helpers'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Chat from 'src/components/common/chat/chat.vue'
import HotlineNumber from 'src/components/common/hotline/hotline_number.vue'
import Modal from 'src/components/common/modal/modal.vue'
import SearchField from './searchfield.vue'
import VueSecureHTML from 'vue-html-secure'

export default {
  name: 'SolutionManager',
  components: {
    Modal,
    SearchField,
    Chat,
    HotlineNumber,
    ButtonComponent
  },
  inject: ['$http'],
  data() {
    return {
      question: '',
      answerPrefix: undefined,
      answerSuffix: undefined,
      answerOptions: [],
      showContactOptions: false,
      sendPositiveFeedback: false,
      faqSuggestions: [],
      feedbackId: `feedback-${uniqueId()}`,
      feedbackTrackingId: undefined,
      titleId: `modal-title-${uniqueId()}`,
      answersRefName: 'answersRef',
      contactOptionsRefName: 'contactOptionsRef'
    }
  },
  computed: {
    hasAnswer() {
      return this.answerPrefix || this.answerOptions.length > 0
    }
  },
  created() {
    this.$bus.on('open-solution-manager', this.open)
  },
  beforeUnmount() {
    this.$bus.off('open-solution-manager', this.open)
  },
  methods: {
    includesHtmlPragraphs(text) {
      return text ? text.includes('<p') : false
    },
    open(question, target) {
      if (question) {
        this.question = question
        this.request(true)
      }

      this.$refs.modal.open(target)
    },
    async requestSuggestions() {
      try {
        const response = await this.$http.get(
          getQueryUrl(
            `https://fonic-iq.novomind.com/${this.$brand.novomindUrlParam}/api/rest/suggestions`,
            {
              search: this.question,
              limit: 5
            }
          ),
          {
            credentials: 'include',
            headers: {
              accept: 'application/vnd.novomind.iq-v3.0+json'
            }
          }
        )
        const suggestedQuestions = response.data.map(x => x.question)

        if (suggestedQuestions) {
          this.faqSuggestions = suggestedQuestions || []
        }
      } catch (e) {
        console.error('SolutionManager Suggestion-API Error: ', e)
      }
    },
    async requestAnswer(trackQuestion = false) {
      this.sendPositiveFeedback = false
      this.showContactOptions = false
      try {
        const response = await this.$http.get(
          getQueryUrl(
            `https://fonic-iq.novomind.com/${this.$brand.novomindUrlParam}/api/rest/ask`,
            {
              text: this.question
            }
          ),
          {
            credentials: 'include',
            headers: {
              accept: 'application/vnd.novomind.iq-v3.0+json'
            }
          }
        )

        if (trackQuestion) {
          const result = await this.$http.post('/rest-api/v1/question', {
            source: 'legacy',
            text: this.question
          })

          this.feedbackTrackingId = result.data.id
        }

        if (response.data.response) {
          const answer = removeWhitespace(
            VueSecureHTML.safeHTML(response.data.response)
          )

          const { prefix, suffix, options } = parseOptionTags(answer)

          this.answerPrefix = prefix || this.getPlaceholderAnswer()
          this.answerSuffix = suffix
          this.answerOptions = options || []

          return
        } else {
          this.answerOptions = []
          this.answerPrefix = this.getPlaceholderAnswer()
          this.answerSuffix = undefined
        }
      } catch (e) {
        console.error('SolutionManager Ask-API Error: ', e)
      }
    },
    getPlaceholderAnswer() {
      return (
        'Leider können wir zu dieser Frage keine Antwort finden. Bitte passen Sie Ihre Frage an' +
        (this.faqSuggestions.length > 0
          ? ' oder wählen eine der unten aufgelisteten FAQs aus.'
          : '.')
      )
    },
    async request(trackQuestion) {
      if (
        (this.$refs.question && this.$refs.question.validate(true)) ||
        (this.question && this.question.length > 2)
      ) {
        const promises = [
          this.requestSuggestions(),
          this.requestAnswer(trackQuestion)
        ]

        await Promise.all(promises)
      }
    },
    selectFaqSuggestion(faq) {
      this.answerPrefix = undefined
      this.answerSuffix = undefined
      this.answerOptions = []

      this.question = faq
      this.requestAnswer()
    },
    clickAnswerElement(index) {
      if (this.answerOptions[index]) {
        this.question = this.answerOptions[index]
        this.requestAnswer()
      }
    },
    async submitFeedback(positive) {
      try {
        await this.$http.post(
          `https://fonic-iq.novomind.com/${this.$brand.novomindUrlParam}/api/rest/evaluate`,
          [
            {
              type: true,
              questionNumber: 1,
              evaluation: positive ? 10 : 0
            }
          ],
          {
            credentials: 'include',
            headers: {
              accept: 'application/vnd.novomind.iq-v3.0+json'
            }
          }
        )
      } catch (e) {
        console.error('SolutionManager Evaluation-API Error: ', e.response)
      }

      this.$http.post('/rest-api/v1/question/feedback', {
        questionId: this.feedbackTrackingId,
        rating: positive ? 'positive' : 'negative'
      })
      if (positive) {
        this.sendPositiveFeedback = true
      } else {
        this.showContactOptions = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-container {
  display: flex;
  flex-direction: column;

  .hotline {
    margin: 0.5rem 0;
  }
}

.result-container {
  margin-left: 5px;
  margin-right: 5px;
}

.faq__headline {
  @include font-normal;

  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 1rem;
}

.faq__option {
  margin-top: 7px;
  line-height: 1.2;

  @include breakpoint($from-tablet) {
    margin-top: 4px;
    line-height: 1.5;
  }

  @include breakpoint($from-desktop) {
    margin-top: 2px;
  }
}

.answer__headline {
  @include font-normal;

  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

.answer__content {
  :deep(span) {
    display: inline;
    cursor: pointer;
    white-space: normal;
    color: $c-primary;
    margin-top: 4px;

    @include breakpoint($from-desktop) {
      margin-top: 2px;
    }
  }

  :deep(p) {
    margin-bottom: 6px;
  }
}

.solution-manager_list {
  padding-left: 0;

  li {
    list-style: none;
  }
}

.contact__elements {
  display: flex;

  > * {
    min-width: 50%;
    padding: 5px;
  }

  @include breakpoint($up-to-desktop) {
    flex-direction: column;
  }
}

.feedback {
  margin-top: 20px;

  .question {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .question-text {
    margin: 2px 0 0;
    flex-grow: 1;
  }
}

.feedback-buttons {
  display: flex;
  flex-wrap: wrap;

  button {
    margin: 5px;
  }
}

.search__title {
  padding-top: 5px;
  padding-bottom: 15px;
  flex-grow: 1;
  font-size: 1.5rem;
}

@include breakpoint($up-to-tablet) {
  .search__title {
    font-size: 1.2rem;
  }

  .feedback {
    margin-top: 10px;

    .feedback-buttons {
      margin-top: 5px;
    }
  }

  .answer__headline,
  .faq__headline {
    margin: 5px 0;
  }

  .faq__headline {
    margin-top: 10px;
  }

  :deep(.subtitle) {
    font-size: 1.1rem;
  }
}
</style>
